@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  font-family: var(--font-inter), Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-y: scroll !important;
}

body {
  padding-right: 0 !important;
}

.primary-color-text {
  @apply !text-[#257AEA];
}

.primary-color-border {
  @apply !border-[#257AEA];
}

.primary-color-bg {
  @apply !bg-[#257AEA];
}

.negative-color-bg {
  @apply !bg-[#ed1515];
}

.primary-color-fill {
  @apply !fill-[#257AEA];
}

.background-block-color {
  background-color: #f6f6f6;
}

.landing-header {
  @apply mb-10 ml-20 text-4xl font-bold lg:text-6xl lg:ml-40 lg:mb-16;
}

.landing-header::before {
  @apply relative block w-16 h-1 bg-black -left-20 lg:-left-40 top-6 lg:top-8 lg:w-32;
  content: '';
}

.landing-header-small {
  @apply ml-20 text-3xl font-bold lg:text-4xl lg:ml-40;
}

.landing-header-small::before {
  @apply block h-0.5 bg-black relative -left-20 lg:-left-40 top-5 w-16 lg:w-32;
  content: '';
}

.flex-center {
  @apply flex items-center justify-center;
}

.myDatePicker,
.myDatePickerRent {
  @apply w-full;
}

.myDatePicker .MuiOutlinedInput-input,
.searchAddressRent .MuiOutlinedInput-input {
  @apply p-4 font-medium cursor-pointer;
}

.myDatePickerRent .MuiOutlinedInput-input {
  @apply p-4 font-medium cursor-pointer;
}

.myDatePicker .MuiOutlinedInput-notchedOutline {
  @apply !border border-gray-300 !rounded-xl !px-2;
}

.myDatePickerRent .MuiOutlinedInput-notchedOutline {
  @apply !border border-gray-300 !rounded-xl !px-4;
}

.searchAddressRent .MuiOutlinedInput-notchedOutline {
  @apply !border border-gray-300 !rounded-xl !p-2;
}

.searchAddressRent .MuiOutlinedInput-root {
  @apply !bg-white !rounded-xl;
}

.calendar .MuiPickersDay-root {
  @apply !text-[#257AEA] font-medium;
}

.calendar .Mui-disabled.MuiPickersDay-root {
  @apply !text-gray-400;
}

.calendar .MuiPickersDay-today,
.calendar .Mui-selected {
  @apply !bg-[#257AEA] bg-opacity-10;
}

.active-tab {
  border-width: 2px;
  box-sizing: border-box;
  border-top: 2px solid #257aea !important;
  border-right: 2px solid #257aea !important;
  border-bottom: 0px solid white !important;
  border-left: 2px solid #257aea !important;
}

.active-tab:after {
  content: '';
  position: absolute;
  background-color: white;
  bottom: 0;
  width: 100%;
  height: 4px;
}

.disabled-tab {
  border-width: 2px;
  box-sizing: border-box;
  border-top: 2px solid #c9c9c9 !important;
  border-right: 2px solid #c9c9c9 !important;
  border-bottom: 0px solid #c9c9c9 !important;
  border-left: 2px solid #c9c9c9 !important;
}

.disabled-tab:hover {
  opacity: 0.7;
  border-top: 2px solid #257aea !important;
  border-right: 2px solid #257aea !important;
  border-bottom: 0px solid white !important;
  border-left: 2px solid #257aea !important;
}

.disabled-tab:after {
  content: '';
  position: absolute;
  background-color: #c9c9c9;
  bottom: 0;
  width: 100%;
  height: 2px;
}

.popup-notification::before {
  position: absolute;
  display: inline-block;
  content: '';
  border: 8px solid transparent;
  border-bottom-color: #c9c9c9;
  top: -16px;
  right: 41px;
}

.popup-notification::after {
  position: absolute;
  display: inline-block;
  content: '';
  border: 7px solid transparent;
  border-bottom-color: #fff;
  top: -14px;
  right: 42px;
}
